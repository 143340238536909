import { Injectable } from '@angular/core'
import { io } from 'socket.io-client'
import { Observable, Subject } from 'rxjs'
import { TokenStorageService } from './token-storage.service'
import { ToastrService } from 'ngx-toastr'

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  // private url = 'http://localhost:3001'
  private url = 'https://atlaschatsystem.oa.r.appspot.com'
  private socket: any
  userData: any
  updateNavCount = new Subject<any>();
  updateMsgRoleCount = new Subject<any>();
  openedChatMessage = new Subject<any>();

  messagePageActive: boolean = false

  intervalSetter: any



  constructor(
    private tokenStorage: TokenStorageService,
    private toaster: ToastrService,
  ) {
    this.userData = this.tokenStorage.getUser()
  }

  
  setSocketIoUrl(socketKey: string) {
    this.socket = io(this.url, {
      query: {
        socketKey: socketKey
      }
    })
  }

  connectToSocketIo() {
    // let socketUrl
    // this.socket = io(this.url)
    // this.socket.on('connect', () => {
    //   socketUrl = this.socket.id
    //   this.tokenStorage.storeSocketId(socketUrl)
    // })
  }

  openChatConnection(data: any) {
    this.socket.on('connect', () => {
     this.socket.emit('connected', data)
     this.startPingPongInterval()

    })
  }


  getConnectedUsersList = () => {
    return Observable.create((observer: any) => {
      this.socket.on('emit-connected-user-list', (message: any) => {
        observer.next(message)
      })
    })
  }


  getMessagesDataList = () => {
    return Observable.create((observer: any) => {
      this.socket.on('emit-sent-message', (message: any) => {
        observer.next(message)
      })
    })
  }

  sendMessage(message: any) {
    this.socket.emit('send-message', message)
  }

  startPingPongInterval() {
    this.intervalSetter = setInterval(() => {

      this.socket.emit('ping')
    }, 300000)
  }

  
  disconnectChatConnection() {
     this.socket.close()
     clearInterval(this.intervalSetter)
  }
 

 

  sendTypingNotify(data: any) {
    this.socket.emit('typing-msg', {
      user: data.user,
    })
  }

  sendMsgEvent(data: any) {
    this.socket.emit('send-msg', data)
  }

 

  getTestMsg = () => {
    return Observable.create((observer: any) => {
      this.socket.on('data', (message: any) => {
       /// console.log(message)
        observer.next(message)
      })
    })
  }

  sendOrderNotification(data: any) {
    for (let g = 0; g < data.length; g++) {
      const cur = data[g]
      this.socket.emit('send-order-notification', {
        user: cur,
        sender: this.userData.id + this.userData.first_name,
      })
    }
  }

  getOrderReceived = () => {
    return Observable.create((observer: any) => {
      this.socket.on('vendor-recevied', (message: any) => {
        observer.next(message)
      })
    })
  }

  getNotification() {
    return Observable.create((observer: any) => {
      this.socket.on('notification', (data: any) => {
        console.log(data)
        observer.next(data)
      })
    })

    this.socket.on('notification', (data: any) => {
      this.toaster.success('you have a new message', 'Chat Notification')
      console.log(data)
      //observer.next(message)
    })
  }

  getTyping = () => {
    return Observable.create((observer: any) => {
      this.socket.on('typing', (message: any) => {
        observer.next(message)
      })
    })
  }

  getMessages = () => {
    return Observable.create((observer: any) => {
      this.socket.on('msgRec', (message: any) => {
        observer.next(message)
      })
    })
  }

  getMessageNotifications = () => {
    return Observable.create((observer: any) => {
      this.socket.on('emit-sent-message-notification', (message: any) => {
        observer.next(message)
      })
    })
  }


}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AudioServiceService {

  constructor() { }

  playAudio(): void {
    const audio = new Audio();
    audio.src = '../../../../assets/new-message-notification.mp3';
  //  audio.load();
    audio.play();
  }

}
